




















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import CreateManufacturer from './create-form.vue';
import EditManufacturer from './edit-form.vue';

class PageManufacturerRequest extends PageRequest {
}

@Component({
    components: { ListView, CreateManufacturer, EditManufacturer }

})
export default class Manufacturers extends ListBase {
    entity: string = 'manufacturer';
    pagerequest: PageManufacturerRequest = new PageManufacturerRequest();

    get loading() {
        return this.$store.state.generaltype.loading;
    }

    edit() {
        this.editModalShow = true;
    }

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]

    mounted() {
        this.modalShow = true;
    }
}