











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Manufacturer from '@/store/entities/catalogs/manufacturer';
import ManufacturerModel from '@/store/entities/catalogs/manufacturermodel';

@Component({
    components: { ModalView, FormBase },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.modalSetting.showDialog = obj.showDialog;
                obj.manufacturer = Util.extend(true, {}, this.$store.state.manufacturer.editModel);
                obj.model = Object.assign({});
                
                if (obj.showModels) {
                    this.$store.commit('manufacturermodel/setList', obj.manufacturer);
                    obj.modalSetting.width = "1100px";
                    obj.formSize = 4;
                } else {
                    obj.modalSetting.width = "500px";
                    obj.formSize = 12;
                }
            }
        }
    }
})

export default class ManufacturerForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Boolean, default: true }) showDialog: boolean;
    manufacturer: Manufacturer = new Manufacturer();
    model: ManufacturerModel = new ManufacturerModel();
    savingModel: boolean = false;
    formBase: boolean = true;
    formSize: number = 12;
    modalSetting: any = {
        entity: "manufacturer",
        title: 'Marca',
        width: "600px",
        showForm: false,
        showDialog: false
    };

    get showModels() {
        return this.$store.state.manufacturer.editModel.id > 0;
    }

    get models() {
        return this.$store.state.manufacturermodel.list;
    }
    
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 12, required: true }),
        this.gItem({ type: 'active' })
    ]

    async saveModel() {
        if (this.$refs.formModel != undefined && (this.$refs.formModel as any).validate()) {
            this.savingModel = true;
            this.model.manufacturer_id = this.$store.state.manufacturer.editModel.id;
            await this.$store.dispatch({
                type: 'manufacturermodel/create',
                data: this.model
            });
            (this.$refs.formModel as any).reset();
            this.manufacturer.models = this.$store.state.manufacturer.editModel.models;
            this.savingModel = false;
        }
    }

    async deleteModel(model) {
        this.savingModel = true;
        await this.$store.dispatch({
            type: 'manufacturermodel/delete',
            data: model.id
        });        
        this.manufacturer.models = this.$store.state.manufacturer.editModel.models;
        this.savingModel = false;
    }

    async save() {
        if (this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let model = Util.prepEntity(this, this.items, this.manufacturer);
            let newModel = await this.$store.dispatch({
                type: 'manufacturer/update',
                data: model
            });
            (this.$refs.entityForm as any).reset();
            this.$emit('save-success', newModel);
            this.$emit('input', false);
            Util.abp.notify.success(this, 'Guardado Correctamente');
        }
    }

    cancel() {
        this.$emit('input', false);
    }
    
    mounted() {
        this.modalSetting.showDialog = this.showDialog;
    }
    
}
