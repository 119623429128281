





import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Manufacturer from '@/store/entities/catalogs/manufacturer';

@Component({
    components: { ModalView },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.modalSetting.showDialog = obj.showDialog;
                obj.initForm();
            } else {
                this.$emit('input', false);
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class ManufacturerForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Boolean, default: true }) showDialog: boolean;
    manufacturer: Manufacturer = new Manufacturer();
    modalSetting: any = {
        entity: "manufacturer",
        title: 'Marca',
        width: "500px",
        titleFull: 'Agregar Marca',
        showDialog: true
    };

    initForm() {
    }
            
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 12, required: true }),
        this.gItem({ type: 'active', visible: true })
    ]

    afterSave(model) {
        this.$emit('save-success', model);
        this.$emit('input', false);
    }
    
    cancel() {
        this.$emit('input', false);
    }

    mounted() {
        this.modalSetting.showDialog = this.showDialog;
    }

    async created() {
    }
}
