














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import DocumentType from '@/store/entities/catalogs/documenttype';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageDocumentTypeRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class DocumentTypes extends ListBase {
    pagerequest: PageDocumentTypeRequest = new PageDocumentTypeRequest();
    documenttype: DocumentType = new DocumentType();
    modalSetting: any = {
        entity: "documenttype",
        title: 'Tipo de Documento',
        width: "500px"
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }
    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', size: 4, required: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 8, required: true }),
        this.gItem({ type: 'text', prop: 'limit', label: 'Limite', size: 6 }),
        this.gItem({ type: 'text', prop: 'reference', label: 'Codigo Sunat', size: 6 }),
        this.gItem({ type: 'text', prop: 'description', label: 'Descripcion' }),
        
    ]
    columns = this.gColumns(this.items, 'description,reference')
    async created() {
        //this.getpage();
    }
}