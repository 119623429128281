




















































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '../../../components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import Company from '@/views/admin/company/company.vue';
import GeneralTypes from '@/views/preferences/generaltype/generaltype.vue';
import Currencies from '@/views/catalogs/currency/currency.vue';
import DocumentTypes from '@/views/catalogs/documenttype/documenttype.vue';
import Manufacturers from '@/views/catalogs/manufacturer/manufacturer.vue';
import Alerts from '@/views/catalogs/alert/alert.vue';


@Component({
    components: { VWidget, FormBase, Company, GeneralTypes, Currencies, DocumentTypes, Manufacturers, Alerts }
})
export default class Departments extends ListBase {
    model: any = {};
    tabModel: any = null;
    formBase: boolean = false;
    file: null;

    get saving() {
        return this.$store.state.configuration.loading;
    }

    generalItems = [
        //this.gItem({ type: 'subheader', subheader: 'Encuestas de Satisfaccion' }),
        //this.gItem({ type: 'text', prop: 'FRONTEND_LINK', label: 'Link del sistema', size: 6 }),
        //this.gItem({ type: 'text', prop: 'QUIZ_URL', label: 'Link para la encuesta de satisfaccion', size: 6 }),
        //this.gItem({ type: 'subheader', subheader: 'Alertas Seguimiento' }),
        //this.gItem({ type: 'number', prop: 'TRACING_ALERT_DAY', label: 'Dias Antes de Vencimiento', suffix: 'Dias', hint: 'Indica la cantidad de dias previos a vencimiento para notificar', persistentHint: true, size: 4 }),
        //this.gItem({ type: 'switch', prop: 'ENABLE_ALERT_TODAY', label: 'Enviar alerta el dia de vencimiento', size: 4 }),
        //this.gItem({ type: 'switch', prop: 'ENABLE_ALERT_DUE', label: 'Enviar alerta cuando se haya vencido', size: 4 }),
        //this.gItem({ type: 'subheader', subheader: 'Integracion API Consulta' }),
        //this.gItem({ type: 'text', prop: 'SIMA_TOKEN', label: 'Token de integracion para API Sima', size: 12 }),
        //this.gItem({ type: 'text', prop: 'CASIS_API', label: 'API de integracion para Consulta de Casis', size: 12 }),            
    ]

    financeItems = [
        this.gItem({ type: 'subheader', subheader: 'Valores por Defecto' }),
        this.gItem({ type: 'radio', prop: 'DEFAULT_VERTICAL', label: 'Vertical por Defecto', size: 6, generalType: 'T_VERTICAL', row:true }),
        this.gItem({ type: 'object', prop: 'DEFAULT_CURRENCY', entity: 'currency', label: 'Moneda por Defecto', size: 6 }),

        this.gItem({ type: 'subheader', subheader: 'Configuracion' }),
        this.gItem({ type: 'text', prop: 'EXPIRATION_DAYS', label: 'Dias de espera para Solicitudes Observadas', size: 4, suffix: 'dias' }),
        this.gItem({ type: 'spacer', size: 8 }),

        this.gItem({ type: 'divider', subheader: 'Integracion Api Sentinel' }),
        this.gItem({ type: 'text', prop: 'SENTINEL_API', label: 'API Sentinel', size: 6 }),
        this.gItem({ type: 'text', prop: 'SENTINEL_KEY', label: 'KEY Sentinel', size: 6 }),
        this.gItem({ type: 'text', prop: 'SENTINEL_USER', label: 'Usuario de Sentinel', size: 6 }),
        this.gItem({ type: 'text', prop: 'SENTINEL_PASSWORD', label: 'Password de Sentinel', size: 6 }),

    ]

    buildModel(data) {
        let items = [...this.generalItems, ...this.financeItems]
        let model = { };
        items.forEach(item => {
            if (item.prop != undefined && typeof data[item.prop] == "object") {
                var prop = item.prop;
                if (data[item.prop] != undefined) {
                    model[prop] = data[item.prop].id;
                }
            } else if (item.prop != undefined) {
                model[item.prop] = data[item.prop];
            }

        });
        return model;
    }

    async save() {           
        let model = this.buildModel(this.model);
        await this.$store.dispatch({
            type: 'configuration/save',
            data: model
        });
        Util.abp.notify.success(this, 'Los ajustes han sido actualizados correctamente.');
    }

    mounted() {
        this.model = Util.extend(true, {}, Util.abp.setting.values);
    }

    async created() {
        this.formBase = true;
    }
}
